.methodDescriptor {
  margin-top: 0px;
  margin-left: 2%;
  color: rgb(63, 62, 62);
}

.Dropdown-control {
  width: 350px;
  height: 30px;
}

.centredDiv {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

#btnCreateGame {
  width: 10%;
  margin-top: 1%;
  padding: 0.5%;
}

#inputGroupsListDiv {
  padding: 10px;
  padding-left: 0px;
}

#btnDeleteName {
  position: absolute;
  left: 6%;
}

.indentedDiv {
  margin-left: 1%;
}

#groupModeDiv {
  margin: 1%;
  margin-left: 0px;
}

#groupModeLabel {
  font-size: large;
}

.expired-notice {
  text-align: center;
  padding: 2rem;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
  margin: 0.5rem;
}

.expired-notice > span {
  font-size: 2.5rem;
  font-weight: bold;
  color: red;
}

.expired-notice > p {
  font-size: 1.5rem;
}

.show-counter {
  padding: 0.5rem;
}

.show-counter .countdown-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.75rem;
  padding: 0.5rem;
  border: 1px solid #ebebeb;
  border-radius: 0.25rem;
  text-decoration: none;
  color: #000;
}

.show-counter .countdown {
  line-height: 1.25rem;
  padding: 0 0.75rem 0 0.75rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.show-counter .countdown.danger {
  color: #ff0000;
}

.show-counter .countdown > p {
  margin: 0;
}

.show-counter .countdown > span {
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1rem;
}
